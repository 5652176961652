<template>
  <v-container fluid>
    <RequestTabs />
    <v-row class="mt-5">
      <v-col md="6" cols="12">
        <div class="conOfHeader pl-5">
          <div class="headerOfPage">Printing Requests</div>
        </div>
      </v-col>
      <v-col md="6" cols="12" class="d-flex justify-end">
        <div class="conOfHeader text-right pr-5">
          <div class="btnAdd">
            <v-btn
              class="btnAddPrimary"
              :disabled="
                createdByUser.id != user.id &&
                assignTo!=user.id
              "
              @click="dialogProject = true"
            >
              <v-icon>mdi-plus</v-icon>
              Create New Request
            </v-btn>
          </div>
        </div>
        <div class="conOfHeader text-right pr-5">
          <a class="exportLink">
            <div class="btnAdd">
              <v-btn
                class="btnAddPrimary"
                @click="exportRequest()"
                :disabled="listData.length == 0"
              >
                <v-icon>mdi-download</v-icon>
                Export Requests
              </v-btn>
            </div>
          </a>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex mb-5 justify-space-between">
      <form class="conFormSubmit conSearchForm mt-5">
        <v-row>
          <v-col md="4" sm="6" cols="12">
            <label class="eachLabel">Created By</label>
            <v-autocomplete
              outlined
              v-model="search.created_by"
              item-text="name"
              item-value="id"
              :items="listTeamLeaders"
              placeholder="Created By"
              hide-details="auto"
              append-icon="mdi-chevron-down"
              :disabled="isLoadingUsersList"
              :loading="isLoadingUsersList"
              :menu-props="{ bottom: true, offsetY: true }"
              attach
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.name }}
                <span class="leaderSpan">
                  {{ data.item.team_leader ? "Leader" : "" }}
                </span>
              </template>
              <template slot="item" slot-scope="data">
                {{ data.item.name }}
                <span class="leaderSpan">
                  {{ data.item.team_leader ? "Leader" : "" }}
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col md="4" sm="6" cols="12">
            <label class="eachLabel">Status</label>
            <v-select
              outlined
              v-model="search.status"
              :items="statusList"
              item-text="name"
              item-value="id"
              placeholder="Status"
              hide-details="true"
              :menu-props="{ bottom: true, offsetY: true }"
              attach
              append-icon="mdi-chevron-down"
            ></v-select>
          </v-col>

          <v-col md="3" sm="6" cols="12">
            <div class="confBtns d-flex">
              <v-btn
                class="srearchBnt btnAddPrimaryOutline mr-4"
                @click="onSearch"
                :disabled="isLoadingSearch"
                :loading="isLoadingSearch"
              >
                SEARCH
              </v-btn>
              <v-btn class="clearBtn btnCancelOutline" @click="onClear">
                CLEAR
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </form>

      <form
        v-on:submit.prevent="onSearchTxt()"
        class="conFormSubmit conSearchForm mt-5"
      >
        <v-row>
          <v-col cols="12">
            <label class="eachLabel">Search</label>
            <v-text-field
              outlined
              v-model="searchTxt"
              type="text"
              class="form-control"
              id="inputSearch"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-addon"
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </form>
    </div>

    <v-row v-if="listData && listData.length > 0">
      <v-col cols="12">
        <section class="conOfProjects">
          <div class="conOfTable">
            <v-data-table
              :headers="headers"
              :items="listData"
              :items-per-page="5"
              hide-default-footer
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom :left="true" :offset-y="true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      :to="{
                        name: 'request-process',
                        params: { id: item.id },
                      }"
                    >
                      <v-list-item-title class="statusTxt">
                        View Requests
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="editItem(item, 1)"
                      v-if="item.status <= 6 && item.status != 0"
                    >
                      <v-list-item-title class="statusTxt">
                        Edit
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="changeCancelItem(item.id, 0)"
                      v-if="
                          item.status_cost == null &&
                        item.status != 0 &&
                        user.id == createdByUser.id
                      "
                    >
                      <v-list-item-title class="statusTxt">
                        Cancel
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="changeStatusItem(item.id)"
                      v-if="
                        item.status_cost == null &&
                        item.status != 0 &&
                        user.id == createdByUser.id
                      "
                    >
                      <v-list-item-title class="statusTxt">
                        Delete
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="changeCancelItem(item.id, 1)"
                      v-if="item.status_cost == null && item.status == 0"
                    >
                      <v-list-item-title class="statusTxt">
                        Reopen
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="editItem(item, '2')"
                      v-if="item.status > 0"
                    >
                      <v-list-item-title class="statusTxt">
                        Add Attaches
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <div>{{ item.created_at | formatDate }}</div>
              </template>
              <template v-slot:[`item.id`]="{ item }">
                <v-badge
                  class="mr-3"
                  bordered
                  dot
                  color="blue"
                  v-if="item.edited == 1"
                >
                </v-badge>
                <router-link
                  :to="{
                    name: 'request-process',
                    params: { id: item.id },
                  }"
                >
                  {{ item.id }}
                </router-link>
              </template>
            </v-data-table>
          </div>
        </section>
        <div
          class="conOfPagination pr-5 pt-5"
          v-if="pagination.total > pagination.per_page"
        >
          <v-pagination
            :total-visible="7"
            v-model="pagination.current_page"
            :length="pagination.last_page"
            @input="goToPage(pagination.current_page)"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col cols="12" class="mt-7">
        <EmptyState />
      </v-col>
    </v-row>

    <v-dialog scrollable v-model="dialogProject" max-width="800" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle"> {{ formTitle }} </span>
        </v-card-title>
        <v-card-text class="conFormSubmit scrollbar">
          <v-container>
            <v-row>
              <v-col md="6" cols="12">
                <label class="eachLabel">Service Type</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Service Type"
                  outlined
                  solo
                  hide-details="auto"
                  value="Printing"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col md="6" cols="12">
                <label class="eachLabel">Item Name</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Item Name"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItem.item_name"
                  required
                  :error-messages="item_nameErrors"
                  @input="$v.formItem.item_name.$touch()"
                  @blur="$v.formItem.item_name.$touch()"
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <label class="eachLabel">Quantity</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Quantity"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItem.quantity"
                  required
                  :error-messages="quantityErrors"
                  @input="$v.formItem.quantity.$touch()"
                  @blur="$v.formItem.quantity.$touch()"
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <label class="eachLabel">Pages</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Pages"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItem.pages"
                  required
                  :error-messages="pagesErrors"
                  @input="$v.formItem.pages.$touch()"
                  @blur="$v.formItem.pages.$touch()"
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <label class="eachLabel">Paper Weight</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Paper Weight"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItem.paper_weight"
                  required
                  :error-messages="paper_weightErrors"
                  @input="$v.formItem.paper_weight.$touch()"
                  @blur="$v.formItem.paper_weight.$touch()"
                ></v-text-field>
              </v-col>
              <v-col md="6" cols="12">
                <label class="eachLabel">Designer In Charge</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Designer In Charge"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItem.designer_name"
                  required
                  :error-messages="designer_nameErrors"
                  @input="$v.formItem.designer_name.$touch()"
                  @blur="$v.formItem.designer_name.$touch()"
                ></v-text-field>
              </v-col>
              <v-col md="6" cols="12" v-if="editedIndex == -1">
                <label class="eachLabel">Attach File</label>
                <v-file-input
                  v-model="attaches"
                  class="eachInput"
                  outlined
                  solo
                  placeholder="Attach File"
                  filled
                  prefix="No file choose"
                  prepend-icon=""
                  multiple
                  truncate-length="5"
                  show-size
                  counter
                  accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
                >
                </v-file-input>
              </v-col>
              <v-col cols="12">
                <label class="eachLabel">Description</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Description"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItem.description"
                  @input="$v.formItem.description.$touch()"
                  @blur="$v.formItem.description.$touch()"
                ></v-text-field>
              </v-col>

              <v-col md="4" cols="12">
                <label class="eachLabel">Print Type</label>
                <v-radio-group
                  mandatory
                  v-model="formItem.print_type"
                  @change="changePrintType"
                  row
                >
                  <v-radio label="Digital" value="Digital"></v-radio>
                  <v-radio label="Offset" value="Offset"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col md="4" cols="12">
                <label class="eachLabel">color</label>
                <v-text-field
                  class="eachInput"
                  placeholder="color"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItem.color"
                  :disabled="formItem.print_type == 'Digital'"
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <label class="eachLabel">Di-Cut</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Di-Cut"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItem.di_cut"
                  required
                  :error-messages="di_cutErrors"
                  @input="$v.formItem.di_cut.$touch()"
                  @blur="$v.formItem.di_cut.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label class="eachLabel">Lamination</label>
                <v-radio-group mandatory v-model="formItem.lamination" row>
                  <v-radio label="Matte" value="Matte"></v-radio>
                  <v-radio label="Glossy" value="Glossy"></v-radio>
                  <v-radio label="None" value="None"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <label class="eachLabel">Binding</label>
                <v-radio-group mandatory v-model="formItem.binding" row>
                  <v-radio label="Wire" value="Wire"></v-radio>
                  <v-radio label="Staple" value="Staple"></v-radio>
                  <v-radio label="None" value="None"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <label class="eachLabel">Delivery Address</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Delivery Address"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItem.delivery_address"
                  required
                  :error-messages="delivery_addressError"
                  @input="$v.formItem.delivery_address.$touch()"
                  @blur="$v.formItem.delivery_address.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label class="eachLabel">Notes</label>
                <v-textarea
                  class="eachInput"
                  placeholder="Notes"
                  outlined
                  rows="2"
                  solo
                  hide-details="auto"
                  v-model="formItem.note"
                  @input="$v.formItem.note.$touch()"
                  @blur="$v.formItem.note.$touch()"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <label class="eachLabel">Requested Date</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Requested Date"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="requestedDate"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeProjectModal">
            Cancel
          </v-btn>
          <!-- <v-btn class="btnPrimaryOrg" v-if="editedIndex == -1">
            Save As Draft
          </v-btn> -->
          <v-btn
            class="btnPrimaryGreen"
            @click="saveItem"
            :disabled="isLoadingSave"
            :loading="isLoadingSave"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAttachesEditMode" max-width="500" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">Upload Attaches</span>
        </v-card-title>

        <v-card-text class="conFormSubmit scrollbar">
          <v-container>
            <v-row>
              <v-col cols="12">
                <label class="eachLabel">Attach File</label>
                <v-file-input
                  v-model="attaches"
                  class="eachInput"
                  outlined
                  solo
                  placeholder="Attach File"
                  filled
                  prefix="No file choose"
                  prepend-icon=""
                  multiple
                  truncate-length="5"
                  show-size
                  counter
                  accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
                >
                </v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeProjectModal">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryGreen"
            @click="saveAttaches"
            :disabled="isLoadingSaveAttaches"
            :loading="isLoadingSaveAttaches"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      v-if="dialogConform"
      v-model="dialogConform"
      @confirmAction="cofirmAction()"
    />
    <ConfirmDialog
      v-if="dialogConformCancel"
      v-model="dialogConformCancel"
      @confirmAction="cancelAction()"
    />
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
import ConfirmDialog from "@/modules/shared/components/confirm-popup";

let apiServices = new ApiService();
import { validationMixin } from "vuelidate";
import statusList from "@/modules/shared/mixins/statusList";
import { required, numeric } from "vuelidate/lib/validators";
import RequestTabs from "../../shared/components/requestTabs";
import EmptyState from "@/modules/shared/components/emptystate";
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  mixins: [validationMixin, statusList],
  validations() {
    const validations = {
      formItem: {
        item_name: { required },
        quantity: { required, numeric },
        pages: { required, numeric },
        paper_weight: { required, numeric },
        designer_name: { required },
        di_cut: { required },
        delivery_address: { required },
      },
    };
    return validations;
  },
  data: () => ({
    dialogProject: false,
    dialogAttachesEditMode: false,
    isLoadingSave: false,
    isLoadingUsersList: false,
    isLoadingSaveAttaches: false,
    requestedDate: null,
    editedIndex: -1,
    isLoadingSearch: false,
    requestId: null,
    cancelRequestId: null,
    cancelRequestStatus: null,
    dialogConformCancel: false,
    dialogConform: false,

    searchTxt: "",

    search: {
      created_by: "",
      status: "",
      costStatus: "",
    },
    updatedFilter: null,
    pagination: {
      current_page: 1,
    },
    branding: false,
    formItem: {},
    defaultItem: {},
    attaches: [],
    defaultImages: { attaches: [] },
    listTeamLeaders: [],
    headers: [
      {
        text: "REQUEST ID",
        align: "start",
        value: "id",
        sortable: false,
      },
      { text: "ITEM NAME", value: "item_name" },
      { text: "SALES CONTACT", value: "created_by_name.name" },
      { text: "STATUS", value: "status_message" },
      { text: "DESIGNER", value: "designer_name" },
      { text: "PRINT TYPE", value: "print_type" },
      { text: "QTY", value: "quantity" },
      { text: "REQUEST DATE", value: "created_at" },
      { text: "", value: "actions", sortable: false },
    ],
    listData: [],
    typeEdit: null,
  }),
  methods: {
    statusChange(item) {
      console.log(item);
    },
    editItem(item, type) {
      this.editedIndex = item.id;
      this.typeEdit = type;

      let editFormItem = {
        project_id: this.$route.params.id,
        type_id: 2,
        item_name: item.item_name,
        quantity: item.quantity,
        pages: item.quantity,
        paper_weight: item.paper_weight,
        designer_name: item.designer_name,
        color: item.color,
        material: item.material,
        delivery_address: item.delivery_address,
        description: item.description,
        note: item.note,
        di_cut: item.di_cut,
      };
      this.formItem = editFormItem;
      this.requestedDate = Vue.filter("formatDate")(item.created_at);
      if (type == "1") this.dialogProject = true;
      else this.dialogAttachesEditMode = true;
    },
    itemEdited() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.isLoadingSave = true;

      apiServices
        .update(this.editedIndex, "requests", this.formItem)
        .then((res) => {
          if (res) {
            this.getListData(this.pagination.current_page);
            this.pagination = res.meta;
            this.isLoadingSave = false;
            this.closeProjectModal();
          } else {
            this.isLoadingSave = false;
          }
        });
    },
    changePrintType() {
      if (this.formItem.print_type == "Digital") this.formItem.color = "";
    },
    saveAttaches() {
      this.storeAttaches(this.editedIndex);
    },
    storeAttaches(id) {
      if (this.typeEdit != "1") this.isLoadingSaveAttaches = true;

      let formData = new FormData();
      for (let attaches of this.attaches) {
        formData.append("attaches[]", attaches);
      }
      formData.append("request_id", id);
      apiServices.post("requests/attaches", formData).then((res) => {
        this.getListData(this.pagination.current_page);
        this.pagination = res.meta;

        if (this.typeEdit == "1") this.isLoadingSave = false;
        else this.isLoadingSaveAttaches = false;

        this.closeProjectModal();
      });
    },
    itemAddNew() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.isLoadingSave = true;

      let form = {
        ...this.formItem,
        project_id: this.$route.params.id,
        type_id: 2,
      };

      apiServices.post("requests", form).then((res) => {
        if (res) {
          if (this.attaches && this.attaches.length > 0) {
            this.storeAttaches(res.data.id);
          } else {
            this.getListData(this.pagination.current_page);
            this.pagination = res.meta;
            this.isLoadingSave = false;
            this.closeProjectModal();
          }
        } else {
          this.isLoadingSave = false;
        }
      });
    },
    changeStatusItem(requestId) {
      this.requestId = requestId;
      this.dialogConform = true;
    },
    changeCancelItem(cancelRequestId, cancelRequestStatus) {
      this.cancelRequestId = cancelRequestId;
      this.cancelRequestStatus = cancelRequestStatus;

      this.dialogConformCancel = true;
    },
    cancelAction() {
      let formData = {
        status: this.cancelRequestStatus,
      };
      apiServices
        .post("requests/action/" + this.cancelRequestId, formData)
        .then((res) => {
          if (res) {
            this.dialogConformCancel = false;
            this.getListData(this.pagination.current_page);
          } else {
            this.dialogConformCancel = false;
          }
        });
    },
    cofirmAction() {
      apiServices.delete("requests/" + this.requestId).then((res) => {
        if (res) {
          this.dialogConform = false;
          this.getListData(this.pagination.current_page);
        } else {
          this.dialogConform = false;
        }
      });
    },

    saveItem() {
      if (this.editedIndex > -1) {
        this.itemEdited();
      } else {
        this.itemAddNew();
      }
    },
    closeProjectModal() {
      this.dialogProject = false;
      this.dialogAttachesEditMode = false;
      this.editedIndex = -1;
      this.isLoadingSave = false;
      this.$nextTick(() => {
        this.$v.$reset();
        this.formItem = Object.assign({}, this.defaultItem);
        this.attaches = [];
        this.branding = false;
        this.getDateNow();
      });
    },
    goToPage(page) {
      this.getListData(page);
      window.scrollTo(0, 0);
    },
    getDateNow() {
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      this.requestedDate = date;
    },
    getUsersList() {
      this.listTeamLeaders = [];
      this.isLoadingUsersList = true;
      apiServices.get(`team/users`).then((res) => {
        if (res) {
          this.listTeamLeaders = res.data;
          this.isLoadingUsersList = false;
        } else this.isLoadingUsersList = false;
      });
    },
    pathByOaramSearch() {
      const query = Object.entries(this.search).reduce((acc, [key, val]) => {
        if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      if (JSON.stringify(this.updatedFilter) != JSON.stringify(query)) {
        this.$router.replace({
          name: "printingAllRequests",
          query: query,
        });
        this.updatedFilter = query;
      }
    },
    pathByOaramSearchTxt() {
      const query = Object.entries(this.searchTxt).reduce((acc, [key, val]) => {
        if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      if (JSON.stringify(this.updatedFilterTxt) != JSON.stringify(query)) {
        this.$router.replace({
          name: "printingAllRequests",
          query: query,
        });
        this.updatedFilterTxt = query;
      }
    },
    initializeForm() {
      this.search.created_by = parseInt(this.$route.query.created_by) || "";
      this.search.status = this.$route.query.status || "";
    },
    getListData(page) {
      this.listData = [];
      this.isLoading = true;
      this.isLoadingSearch = true;
      if (
        this.search.status == 2 ||
        this.search.status == 3 ||
        this.search.status == 4 ||
        this.search.status == 5
      ) {
        this.search.costStatus = this.search.status;
        this.search.status = "";
      } else {
        this.search.costStatus = "";
      }
      apiServices
        .get(
          `requests?per_page=5&page=${page}&type_id=2&project_id=${this.$route.params.id}&created_by=${this.search.created_by}&status=${this.search.status}&cost_status=${this.search.costStatus}&search=${this.searchTxt}`
        )
        .then((res) => {
          if (res) {
            this.listData = res.data;
            this.pagination = res.meta;
            this.isLoading = false;
            this.isLoadingSearch = false;
          } else {
            this.isLoading = false;
            this.isLoadingSearch = false;
          }
        });
    },
    onSearch() {
      this.pagination.current_page = 1;
      this.pathByOaramSearch();
    },
    onSearchTxt() {
      this.pagination.current_page = 1;
      this.pathByOaramSearchTxt();
    },
    onClear() {
      this.search.created_by = "";
      this.search.status = "";
      this.pathByOaramSearch();
    },
    exportRequest() {
      let form = {
        project_id: this.$route.params.id,
        type_id: 2,
      };

      apiServices.post("requests/export", form).then((res) => {
        if (res) {
          this.exportLink = res.data.file;
          const link = document.createElement("a");
          link.href = res.data.file;
          document.body.appendChild(link);
          link.click();
        }
      });
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Request" : "Edit Request";
    },
    item_nameErrors() {
      const errors = [];
      if (!this.$v.formItem.item_name.$dirty) return errors;
      !this.$v.formItem.item_name.required &&
        errors.push("Item Name Is Required.");
      return errors;
    },
    quantityErrors() {
      const errors = [];
      if (!this.$v.formItem.quantity.$dirty) return errors;
      !this.$v.formItem.quantity.required &&
        errors.push("Quantity Is Required.");
      !this.$v.formItem.quantity.numeric &&
        errors.push("quantity should be a number");
      return errors;
    },
    pagesErrors() {
      const errors = [];
      if (!this.$v.formItem.pages.$dirty) return errors;
      !this.$v.formItem.pages.required && errors.push("Pages Is Required.");
      !this.$v.formItem.pages.numeric &&
        errors.push("pages should be a number");
      return errors;
    },
    paper_weightErrors() {
      const errors = [];
      if (!this.$v.formItem.paper_weight.$dirty) return errors;
      !this.$v.formItem.paper_weight.required &&
        errors.push("Paper Weight Is Required.");
      !this.$v.formItem.paper_weight.numeric &&
        errors.push("paper Weight should be a number");
      return errors;
    },
    designer_nameErrors() {
      const errors = [];
      if (!this.$v.formItem.designer_name.$dirty) return errors;
      !this.$v.formItem.designer_name.required &&
        errors.push("Designer In Charge Is Required.");
      return errors;
    },
    di_cutErrors() {
      const errors = [];
      if (!this.$v.formItem.di_cut.$dirty) return errors;
      !this.$v.formItem.di_cut.required && errors.push("Di-Cut Is Required.");
      return errors;
    },
    delivery_addressError() {
      const errors = [];
      if (!this.$v.formItem.delivery_address.$dirty) return errors;
      !this.$v.formItem.delivery_address.required &&
        errors.push("Delivery Address Is Required.");
      return errors;
    },
    ...mapGetters(["createdByUser", "user","assignTo"]),
  },
  created() {
    this.getDateNow();
    this.getUsersList();
  },
  watch: {
    "$route.params.query": {
      handler: function () {
        this.initializeForm();
        this.getListData(this.pagination.current_page);
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    RequestTabs,
    EmptyState,
    ConfirmDialog,
  },
};
</script>
<style lang="scss" scoped>
@import "./_printing.scss";
</style>
